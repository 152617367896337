import { Box, Heading, Paragraph } from "grommet";
import React from "react";
import SiteLayout from "../layout";
export default function NotFound() {
  return (
    <SiteLayout>
      <Box fill justify="center" align="center">
        <Paragraph level="3" color="light-6">
          404
        </Paragraph>
        <Heading level="1" color="light-6">
          Page Not Found!
        </Heading>
      </Box>
    </SiteLayout>
  );
}
